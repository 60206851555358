import axios from "axios";
import {fetchAuthSession, signOut} from "aws-amplify/auth";

const contentClient = axios.create({
    baseURL: process.env.REACT_APP_CONTENT_ENDPOINT
});

contentClient.interceptors.request.use(function (config) {
    return new Promise((resolve, reject) => {
        //get the current logged in user
        fetchAuthSession()
            .then((session) => {
                config.headers.set('X-CLIENT-ID', session.tokens.idToken.toString());
                resolve(config);
            })
            .catch(() => {
                // No logged-in user: don't set auth header
                signOut().then(() => {
                    window.location.href = '/';
                 });
            });
    });
});

// Helpful Debugging tools
// contentClient.interceptors.request.use(request => {
//     console.log('Starting Request', JSON.stringify(request, null, 2))
//     return request
// })

// contentClient.interceptors.response.use(response => {
//     console.log('Response:', JSON.stringify(response, null, 2))
//     return response
// })


export default contentClient;