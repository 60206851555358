import axios from "axios";
import dupeClient from "./DupeClient";
import {fetchAuthSession} from "aws-amplify/auth";

export async function createCollection(collection) {
    const result = await dupeClient.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections', collection)
    return result;
}

export async function updateCollection(collection) {
    const result = await dupeClient.put(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections', collection)
    return result;
}

export async function deleteCollection(collectionId) {
    const result = await dupeClient.delete(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/' + collectionId)
    return result;
}

export async function getCollectionPreviews(userId, contentId) {
    let url = process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/preview?userId=' + userId;
    if (contentId) {
        url = url + "&contentId=" + contentId;
    }
    const session = await fetchAuthSession()
        .catch(() => {

        });
    if (session && session.tokens && session.tokens.idToken) {
        return await axios.get(url, {headers: {'X-CLIENT-ID': session.tokens.idToken.toString()}});
    } else {
        return await axios.get(url);
    }
}

export async function getPortfolioPreviews(userId) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/portfolios/preview?userId=' + userId)
    return result;
}

export async function getCollectionContent(collectionId, page) {
    let url = process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/' + collectionId + '/content';
    if (page) {
        url = url + '?page=' + page;
    }
    const result = await axios.get(url)
    return result;
}

export async function createCollectionEntry(collectionId, collectionEntry) {
    const result = await dupeClient.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/' + collectionId + "/entries", collectionEntry)
    return result;
}

export async function updateCollectionCover(request) {
    const result = await dupeClient.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/cover', request)
    return result;
}

export async function deleteCollectionEntry(collectionEntryId) {
    const result = await dupeClient.delete(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/entries/' + collectionEntryId)
    return result;
}

export async function deleteContentFromCollection(collectionId, contentId) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/collections/' + collectionId + "/content/" + contentId
    const result = await dupeClient.delete(url)
    return result;
}