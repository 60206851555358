import dupeClient from "./DupeClient";
import axios from "axios";
import {fetchAuthSession} from "aws-amplify/auth";

export async function signUp(user) {
    const result = await dupeClient.post('/api/v1/users', user)
    return result;
}

export async function getProfile() {
    const result = await dupeClient.get('/api/v1/profile')
    return result;
}

export async function getUsernameProfile(username, token) {
    if (token) {
        const result = await dupeClient.get('/api/v1/profile/' + username)
        return result;
    } else {
        const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/profile/' + username)
        return result;
    }
}

export async function updateUser(user) {
    const result = await dupeClient.put('/api/v1/users', user)
    return result;
}

export async function getUser(userId) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users/' + userId)
    return result;
}


export async function checkUsername(username) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users/' + username + '/check';
    const session = await fetchAuthSession()
        .catch(() => {

        });
    if (session && session.tokens && session.tokens.idToken) {
        return await axios.get(url, {headers: {'X-CLIENT-ID': session.tokens.idToken.toString()}});
    } else {
        return await axios.get(url);
    }
}
export async function followUser(userId) {
    const result = await dupeClient.post('/api/v1/users/' + userId + '/follow', {})
    return result;
}

export async function unfollowUser(userId) {
    const result = await dupeClient.delete('/api/v1/users/' + userId + '/follow')
    return result;
}

export async function getUsersFollowings(username) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users/' + username + '/following')
    return result;
}

export async function getMessagingFlag() {
    const result = await dupeClient.get('/api/v1/users/messaging/flag')
    return result;
}