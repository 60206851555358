import React, {useEffect, useState} from 'react'
import {uploadBusinessImage, uploadImage, uploadPrivateImage} from "../services/ImageService"
import {createPortfolio, uploadContent} from "../services/ContentService"
import Modal from 'react-modal';
import "../stylesheets/Upload.css";
import "../stylesheets/Upload.mobile.css";
import {getAesthetics} from "../services/AestheticService";
import {useNavigate} from "react-router-dom";
import upload from "../icons/DupeIcons-07-Upload.png"
import loadingIcon from "../icons/loading-icon.png";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader



const Upload = ({portfolioFlag, businessFlag, campaignId, onUploadFunc}) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [uploadedImage, setUploadedImage] = React.useState(false);
    const [loadingImage, setLoadingImage] = React.useState(false);
    const [aesthetics, setAesthetics] = useState([]);
    const [allAesthetics, setAllAesthetics] = useState([]);
    const [aestheticSearch, setAestheticSearch] = useState("");
    const [portfolioTitle, setPortfolioTitle] = useState("");
    const [portfolioDescription, setPortfolioDescription] = useState("");
    const [labels, setLabels] = useState([]);
    const [selectedAesthetics, setSelectedAesthetics] = React.useState([]);
    const [selectedLabels, setSelectedLabels] = React.useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showAesthetics, setShowAesthetics] = React.useState(false);
    const [showLabels, setShowLabels] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const style = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    }

    const checkVideoDuration = (file, isBusiness) => {
        return new Promise((resolve, reject) => {
            if (!file.type.includes('video')) {
                resolve(true);
                return;
            }

            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                
                if (isBusiness) {
                    if (video.duration > 180) {
                        reject(new Error('Video must be less than 3 minutes'));
                    }
                } else {
                    if (video.duration < 3 || video.duration > 15) {
                        reject(new Error('Video must be between 3 and 15 seconds'));
                    }
                }
                resolve(true);
            };

            video.onerror = () => {
                reject(new Error('Error loading video'));
            };

            video.src = URL.createObjectURL(file);
        });
    };

    const triggerUpload = (e) => {
        setLoadingImage(true);
        let formData = new FormData();
        formData.append("img", selectedImage[0]);
        if (portfolioFlag) {
            uploadPrivateImage(formData)
                .then(response => {
                    if (response.status === 200 && response.data.img_id) {
                        onUploadFunc(response.data.id);
                        setLoadingImage(false);
                    }
                })
                .catch(error => {
                    setLoadingImage(false);
                })
        } else if (businessFlag) {
            const durationChecks = selectedImage.map(file => checkVideoDuration(file, true));
            
            Promise.all(durationChecks)
                .then(() => {
                    const uploadPromises = selectedImage.map(image => {
                        let bizFormData = new FormData();
                        bizFormData.append("img", image);
                        return uploadBusinessImage(bizFormData, campaignId);
                    });

                    return Promise.all(uploadPromises);
                })
                .then(() => {
                    setLoadingImage(false);
                    setErrorMessage("");
                    onUploadFunc();
                })
                .catch(error => {
                    setLoadingImage(false);
                    console.log("Error uploading business image", error);
                    setErrorMessage(error.message || (error.response?.data?.error?.message));
                    setSelectedImage(null);
                });
        } else {
            checkVideoDuration(selectedImage[0], false)
                .then(() => {
                    return uploadImage(formData);
                })
                .then(response => {
                    if (response.status === 200 && response.data.img_id) {
                        setUploadedImage(response.data)
                        return uploadContent(createContentRequest(response.data.img_id));
                    }
                })
                .then(response => {
                    if (response && response.status === 200 && response.data.id) {
                        setErrorMessage("");
                        openModal();
                    }
                    setLoadingImage(false);
                })
                .catch(error => {
                    setLoadingImage(false);
                    setErrorMessage(error.message || (error.response?.data?.error?.message));
                    setSelectedImage(null);
                });
        }
    }


    function openModal() {
        setIsOpen(true);
    }

    function createPortfolioRequest(privateContent) {
        var req = {};
        req["img_id"] = privateContent.img_id;
        req["img_preview_id"] = privateContent.img_preview_id;
        req["portfolio_flag"] = true;
        req["title"] = portfolioTitle;
        req["description"] = portfolioDescription;
        return req;
    }

    function createContentRequest(id) {
        var req = {};
        req["img_id"] = id;
        req["portfolio_flag"] = portfolioFlag;
        if (portfolioFlag) {
            req["title"] = portfolioTitle;
            req["description"] = portfolioDescription;
        } else {
            if (selectedLabels) {
                req["labels"] = selectedLabels
            }
            if (selectedAesthetics) {
                req["aesthetics"] = selectedAesthetics;
            }
        }
        return req;
    }


    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setSelectedImage(null);
        setUploadedImage(null);
        setSelectedAesthetics([]);
        setSelectedLabels([]);
        setIsOpen(false);
    }

    function openAestheticDropdown() {
        setShowAesthetics(true);
    }

    function hideAestheticDropdown() {
        setShowAesthetics(false);
    }

    function addAesthetic(aesthetic) {
        if (selectedAesthetics.indexOf(aesthetic) == -1) {
            selectedAesthetics.push(aesthetic);
        }
        setShowAesthetics(false);
        setAestheticSearch('');
    }

    function removeAesthetic(aesthetic) {
        const index = selectedAesthetics.indexOf(aesthetic)
        if (index > -1) {
            selectedAesthetics.splice(index, 1);
            const newArray = [...selectedAesthetics];
            setSelectedAesthetics(newArray);
        }
    }

    function openLabelDropdown() {
        setShowLabels(true);
    }

    function hideLabelDropdown() {
        setShowLabels(false);
    }


    function removeLabel(label) {
        const index = selectedLabels.indexOf(label)
        if (index > -1) {
            selectedLabels.splice(index, 1);
            const newArray = [...selectedLabels];
            setSelectedLabels(newArray);
        }
    }
    function updateTitle(e) {
        setPortfolioTitle(e.target.value);
    }
    function updateDescription(e) {
        e.target.style.cssText = 'height:auto; padding:0';
        e.target.style.cssText = 'height:' + e.target.scrollHeight + 'px';
        setPortfolioDescription(e.target.value);
    }


    function createLabel(event) {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const index = selectedLabels.indexOf(event.target.value)
                if (index == -1) {
                    setSelectedLabels(selectedLabels.concat(event.target.value));
                }
                event.target.value = '';
        }
    }

    function navigateToProfile() {
        navigate('/profile');
    }

    function setSelectedImages(event) {
        let content = [];
        for (let i = 0; i < event.target.files.length; i++) {
            content.push(event.target.files[i]);
        }
        setSelectedImage(content);
    }


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate('/login', { replace: true });
        }
        getAesthetics()
            .then((res) => {
                setAllAesthetics(res.data);
                setAesthetics(res.data);
            })
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (allAesthetics.length > 0) {
                setAesthetics(allAesthetics.filter(aesthetic => {
                    if (aestheticSearch === "") {
                        //if query is empty
                        return aesthetic;
                    } else if (aesthetic.aesthetic.toLowerCase().includes(aestheticSearch.toLowerCase())) {
                        //returns filtered array
                        return aesthetic;
                    }
                }))
            }
        }, 100)

        return () => clearTimeout(delayDebounceFn)
    }, [aestheticSearch])

    return(
        <div className={"upload-container"}>
            <div className={businessFlag ? "single-column" : "upload-column"}>
                {selectedImage && selectedImage.length > 0 && !businessFlag && (
                    <div className="selected-image">
                        {selectedImage[0].type.includes("video") && (
                            <video className="content-image" controls loop autoPlay muted>
                                <source src={URL.createObjectURL(selectedImage[0])}
                                        type={"video/mp4"}/>
                            </video>
                        )}
                        {!selectedImage[0].type.includes("video") && (
                            <img alt="not fount" src={URL.createObjectURL(selectedImage[0])}/>
                        )}
                        <br/>
                        <button className={"btn remove-upload-btn"} onClick={() => {setSelectedImage(null); setErrorMessage("");}}>Remove
                        </button>
                    </div>
                )}
                {selectedImage && businessFlag && (
                    <div className="selected-image">
                        <Carousel showArrows={true}>
                            {selectedImage.map((image) => (
                                <div className="slide">
                                    {image.type.includes("video") && (
                                        <video className="content-image" controls loop autoPlay muted>
                                            <source src={URL.createObjectURL(image)}
                                                    type={"video/mp4"}/>
                                        </video>
                                    )}
                                    {!image.type.includes("video") && (
                                        <img alt="not fount" src={URL.createObjectURL(image)}/>
                                    )}
                                </div>
                            ))}
                        </Carousel>
                    </div>
                )}
                {selectedImage == null && (
                    <div>
                        {errorMessage && (
                            <div className="error-message" style={{ 
                                color: 'red', 
                                marginBottom: '10px',
                                textAlign: 'center'
                            }}>
                                {errorMessage}
                            </div>
                        )}
                        <div className="upload-box" onClick={() => {
                            setErrorMessage("");
                            document.getElementById('imgUpload').click()
                        }}>
                            <img src={upload}
                                 className={"upload-img"}/>
                            <h5 className={"blue-text font-bold"}>Click to upload an image</h5>
                            <input
                                type="file"
                                id={"imgUpload"}
                                style={{display: "none"}}
                                multiple={businessFlag}
                                onChange={(event) => {
                                    setSelectedImages(event);
                                }}
                            />
                            <div className="upload-text">
                                <h5 className={"blue-text font-bold"}>HEY! BTW:</h5>
                                <p>&#x2022;  Use high quality jpeg or png images</p>
                                <p>&#x2022;  Only use images/videos you own the rights to</p>
                                <p>&#x2022;  No nudity, violence, or hate</p>
                                {!businessFlag && (
                                    <>
                                        <p>&#x2022;  Videos must be 3 to 15 seconds</p>
                                        <p>&#x2022;  Videos must be under 100 MB</p>
                                    </>
                                )}
                                {businessFlag && (
                                    <p>&#x2022;  Videos must be under 3 minutes</p>
                                )}
                            </div>
                        </div>
                        <div className={"read-license-div"}>
                            <p>Read the&nbsp;</p><a href={"/license-and-terms"}>Dupe License</a>
                        </div>
                    </div>
                )}
            </div>
            {!businessFlag && (
                <div className="tag-column">
                    <div className={"aesthetic-container"}>
                        {portfolioFlag && (
                            <div>
                                <input value={portfolioTitle} onChange={updateTitle} placeholder={"Title"} className={"aesthetic-input"} maxLength={25}/>
                            </div>
                        )}
                        {!portfolioFlag && (
                            <div>
                                <input className={"aesthetic-input "} value={aestheticSearch} onFocus={openAestheticDropdown}
                                       onBlur={hideAestheticDropdown} onChange={(e) => setAestheticSearch(e.target.value)}
                                       placeholder={(selectedAesthetics.length > 1) ? "Aesthetics are full" : "Add up to two aesthetics"}
                                       disabled={(selectedAesthetics.length > 1) ? "disabled" : ""}/>
                                <div className={showAesthetics ? "aesthetic-dropdown" : "d-none"}>
                                    {aesthetics.map((aesthetic) => (
                                        <div className={"pointer"} key={aesthetic.id} onMouseDown={() => addAesthetic(aesthetic)}>
                                            <p className={"aesthetic-dropdown-p"}>{aesthetic.aesthetic}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className={"upload-aesthetics-div"}>
                                    {(!selectedAesthetics || selectedAesthetics.length < 1) && (
                                        <p className={"aesthetic-warning-p"}>We know not all photos will match an aesthetic. If you can't find one that matches your photo,
                                            you can just leave this section blank. Even without an aesthetic, your photo will still be searchable.</p>
                                    )}
                                    {selectedAesthetics && selectedAesthetics.map((aesthetic) => (
                                        <button className={"aesthetic-button"}
                                                onClick={() => removeAesthetic(aesthetic)}>x {aesthetic.aesthetic}</button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={"label-container"}>
                        {portfolioFlag && (
                            <div>
                            <textarea placeholder={"Description"} value={portfolioDescription}
                                      onChange={updateDescription} maxLength={250} className={"description-input"} />
                            </div>
                        )}
                        {!portfolioFlag && (
                            <div>
                                <input className={"label-input"}
                                       maxLength="25"
                                       onFocus={openLabelDropdown}
                                       onBlur={hideLabelDropdown}
                                       placeholder={(selectedLabels.length > 9) ? "Tags are full" : "Add up to ten tags"}
                                       disabled={(selectedLabels.length > 9) ? "disabled" : ""}
                                       onKeyDown={(event) => createLabel(event)}/>
                                <div className={(showLabels ? "aesthetic-dropdown" : "d-none")}>
                                    {labels && labels.map((label) => (
                                        <div onMouseDown={(event) => addAesthetic(event, label)}>
                                            <p>{label}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className={"upload-aesthetics-div"}>
                                    {(!selectedLabels || selectedLabels.length < 1) && (
                                        <p className={"aesthetic-warning-p"}>Hit enter to add tag</p>
                                    )}
                                    {selectedLabels && selectedLabels.map((label) => (
                                        <button className={"label-button"} onClick={() => removeLabel(label)}>x {label}</button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="upload-submit-div">
                <button className={"blue-btn"} type="button" onClick={(e) => triggerUpload()}>Submit</button>
            </div>
            {loadingImage && (
                <div className={"loading-div"}>
                    <img src={loadingIcon}/>
                </div>
            )}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"upload-modal"}
                style={style}
            >
                <div className={"upload-submission-container"}>
                    {uploadedImage &&
                    <div className="upload-image-preview">
                        {uploadedImage.content_type === "VIDEO" && (
                            <video className="content-image" controls loop autoPlay muted>
                                <source src={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + uploadedImage.img_id}
                                        type={"video/mp4"}/>
                            </video>
                            )}
                        {uploadedImage.content_type !== "VIDEO" && (
                            <img alt="not fount" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + uploadedImage.img_preview_id}/>
                            )}
                    </div>
                    }
                    <div className="upload-display">
                        <h3 className={"gold-text font-bold dude-h"}>DUDE!</h3>
                        <h3 className={"blue-text font-bold submitted-h"}>Your photo</h3>
                        <h3 className={"blue-text font-bold submitted-h"}>has been</h3>
                        <h3 className={"blue-text font-bold submitted-h"}>submitted!</h3>
                        <p className={"font-medium"}>We'll hit ya up once it has been approved.</p>
                        <p>We evaluate each photo through a series of requirements. Click <a href={"https://www.blog.dupephotos.com/style-guide"}>here</a> to take a look at our Style Guide.</p>
                        <div className="upload-submission-footer-div">
                            <button className={"gold-btn"} onClick={navigateToProfile}>Go to Profile</button>
                            <button className={"gold-inverse-btn"} onClick={closeModal}>Upload More</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Upload
